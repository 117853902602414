<script setup lang="ts">
import type { Media, MediaType } from '~/types'

const props = withDefaults(
  defineProps<{
    items: Media[]
    type?: MediaType
    fetch: (page: number) => Promise<void>
    count?: number
    blocking?: boolean
    isDelete?: boolean
    isShow?: boolean
  }>(),
  {
    isShow: true,
    blocking: true
  }
)

const emits = defineEmits(['handleDelete'])

function cardType(val: number) {
  const typeList = ['movie', 'tv', 'show', 'cartoon', 'other', 'guess']
  return typeList[val - 1]
}

const tailEl = ref<HTMLDivElement>()

let page = 1
const isLoading = ref<boolean>(false)

async function loadingNext() {
  if (isLoading.value)
    return
  isLoading.value = true
  try {
    page += 1
    await props.fetch(page)
  } finally {
    isLoading.value = false
  }
}
useInfiniteScroll(
  tailEl,
  () => {
    if (process.client) {
      if (!tailEl.value || isLoading.value) {
        return false
      }
      if (!!props.count && props.items?.length >= props.count) {
        return false
      }
      loadingNext()
    }
  },
  { distance: 300, interval: 200 }
)

function handleDelete(val: Media) {
  emits('handleDelete', val)
}
</script>

<template>
  <div>
    <h1 flex gap2 text-2xl>
      <slot />
    </h1>
    <MediaGrid>
      <MediaCard
        v-for="item of items"
        :key="item.id"
        :type="cardType(item.videoSiteType)"
        :item="item"
        :is-delete="isDelete"
        :is-show="isShow"
        @handle-delete="(val) => handleDelete(val)"
      />
    </MediaGrid>
    <div ref="tailEl" />
    <div v-if="isLoading" animate-pulse p10>
      <div i-carbon:circle-dash ma animate-spin text-4xl />
    </div>
  </div>
</template>
